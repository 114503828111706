<template>
  <base-card
      class="pb-5"
  >
    <!--Parralax
    <v-parallax
        :height="$vuetify.breakpoint.mdAndUp ? 300 : 300"
        :lazy-src="`${require('@/assets/img/pulheim.jpg')}?h=500&fm=jpg&fl=progressive&q=50`"
        :src="`${require('@/assets/img/pulheim.jpg')}?h=1200&fm=jpg&fl=progressive&q=100`"
    >
      <v-overlay
          value="true"
          absolute
      >
        <v-container fluid>
          <v-row justify="center">
            <v-card-title
                id="about"
                :class="[$vuetify.breakpoint.mdAndUp ? 'display-3' : 'headline']"
                class="justify-center text--white text-uppercase logo mt-6 my-3"
            >
              {{$t('agenda.agenda_2028')}}
            </v-card-title>
          </v-row>
        </v-container>
      </v-overlay>
    </v-parallax>-->
    <!--About-Text-->
    <v-container
        class="d-flex align-center"
    >
      <v-skeleton-loader
          type="paragraph@6"
          :loading="loading"
          class="mt-10"
      >
        <template v-slot:default>
          <v-card-text
              id="about"
              class="text-xs-center text-lg-justify text-lg-h6 text-sm-subtitle-1 font-weight-light mx-auto agenda"
              v-html="agenda"
          >
            {{agenda}}
          </v-card-text>
        </template>
      </v-skeleton-loader>
    </v-container>
    <!--Parallax-->
    <v-parallax
        :height="$vuetify.breakpoint.mdAndUp ? 800 : 300"
        :lazy-src="`${require('@/assets/img/gladbeck_gruppenfoto.jpg')}?h=500&fm=jpg&fl=progressive&q=50`"
        :src="`${require('@/assets/img/gladbeck_gruppenfoto.jpg')}?h=1200&fm=jpg&fl=progressive&q=100`"
    >
      <v-overlay
          value="true"
          absolute
      >
        <v-container fluid>
          <v-row justify="center">
            <v-col
                class="font-weight-bold white--text text-uppercase headline logo"
            >
              #JEMEINSAM
            </v-col>
          </v-row>
        </v-container>
      </v-overlay>
    </v-parallax>
  </base-card>
</template>

<script>
import {fetchData, query_AGENDA} from '@//utils/api'
import i18n from '@//plugins/i18n'
import showdown from 'showdown'

export default {
  data: ()=>({
    loading: true,
    agenda: {},
  }),
  methods: {
    getAgenda: async () => {
      const query = query_AGENDA(i18n.locale)
      return await fetchData(query)
    }
  },
  async created() {
    const agenda = await this.getAgenda()
    const converter = new showdown.Converter()
    this.agenda = converter.makeHtml(agenda.textContent.text)
    this.loading =false

    this.$eventHub.$on('locale-changed', async()=>{
      this.loading =true
      const agenda = await this.getAgenda()
      const converter = new showdown.Converter()
      this.agenda = converter.makeHtml(agenda.textContent.text)
      this.loading =false
    })
  },
}
</script>
<style scoped lang="sass">
@import 'src/styles/variables.scss'
@import "@/assets/BinarioSoft-LightOblique.scss"
@import "@/assets/BinarioSoft-Oblique.scss"
::v-deep
  .agenda p
    margin: 30px 0

  .agenda h3
    margin-top: 30px
    font-family: $logo
    font-weight: bold
    font-style: oblique
</style>
